import { baseRoutes } from '../../core/config/routes';

export const routes = {
  ...baseRoutes,
  admin: {
    ...baseRoutes.admin,
    categories: {
      list: '/admin/categories',
      edit: '/admin/categories/:id',
    },
    articleCategories: {
      list: '/admin/article-categories',
      edit: '/admin/article-categories/:id',
      new: '/admin/article-categories/new',
    },
    brands: {
      list: '/admin/brands',
      edit: '/admin/brands/:id',
    },
    manufacturers: {
      list: '/admin/manufacturers',
      edit: '/admin/manufacturers/:id',
    },
    vehicles: {
      list: '/admin/vehicles',
    },
    products: {
      list: '/admin/products',
      edit: '/admin/products/:id',
    },
    blogs: {
      list: '/admin/blogs',
      new: '/admin/blogs/new',
      edit: '/admin/blogs/:id',
    },
    staticPages: {
      list: '/admin/static-pages',
      edit: '/admin/static-pages/:id',
    },
    orders: {
      list: '/admin/orders',
    },
    vatRates: {
      list: '/admin/vat-rates',
      edit: '/admin/vat-rates/:id',
    },
    baskets: {
      list: '/admin/baskets',
    },
    faq: {
      list: '/admin/faq',
      new: '/admin/faq/new',
      edit: '/admin/faq/:id',
    },
    returnOrders: {
      list: '/admin/return-orders',
      new: '/admin/return-orders/new',
      edit: '/admin/return-orders/:id',
    },
    priceVariants: {
      list: '/admin/priceVariants',
      new: '/admin/priceVariants/new',
      edit: '/admin/priceVariants/:id',
    },
    clients: {
      list: '/admin/clients',
      new: '/admin/clients/new',
      edit: '/admin/clients/:id',
    },
    clientRepresentatives: {
      new: '/admin/clients/:client/users',
      edit: '/admin/clients/client-users/:id',
    },
    promoCodes: {
      list: '/admin/promo-codes',
      edit: '/admin/promo-codes/:id',
      new: '/admin/promo-codes/new',
    },
  },
  auth: {
    ...baseRoutes.auth,
  },
};
