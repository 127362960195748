import React from 'react';
import { routes } from '../routes/routes';
import { apiRoutes } from '../api/apiRoutes';
import AccountBox from '@mui/icons-material/AccountBox';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import Speed from '@mui/icons-material/Speed';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ProductQuantityLimitIcon from '@mui/icons-material/ProductionQuantityLimits';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DiscountIcon from '@mui/icons-material/Discount';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactoryIcon from '@mui/icons-material/Factory';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArticleIcon from '@mui/icons-material/Article';
import ClassIcon from '@mui/icons-material/Class';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import QuestionIcon from '@mui/icons-material/QuestionMark';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PeopleIcon from '@mui/icons-material/People';
import PercentIcon from '@mui/icons-material/Percent';

export const companySidebarItems = [];

export const sidebarItems = [
  {
    title: 'SIDEBAR.GROUP_GENERAL',
    items: [
      {
        title: 'SIDEBAR.DASHBOARD',
        icon: <Speed />,
        url: routes.admin.dashboard,
      },
      {
        title: 'SIDEBAR.ORDERS',
        icon: <MonetizationOnIcon />,
        url: routes.admin.orders.list,
      },
      {
        title: 'SIDEBAR.RETURN_ORDERS',
        icon: <ProductQuantityLimitIcon />,
        url: routes.admin.returnOrders.list,
      },
      {
        title: 'SIDEBAR.BASKETS',
        icon: <ShoppingCartIcon />,
        url: routes.admin.baskets.list,
      },
      {
        title: 'SIDEBAR.PROMO_CODE',
        icon: <DiscountIcon />,
        url: routes.admin.promoCodes.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_INVENTORY',
    items: [
      {
        title: 'SIDEBAR.PRODUCTS',
        icon: <PrecisionManufacturingIcon />,
        url: routes.admin.products.list,
      },
      {
        title: 'SIDEBAR.CATEGORIES',
        icon: <CategoryIcon />,
        url: routes.admin.categories.list,
      },
      {
        title: 'SIDEBAR.BRANDS',
        icon: <WarehouseIcon />,
        url: routes.admin.brands.list,
      },
      {
        title: 'SIDEBAR.MANUFACTURERS',
        icon: <FactoryIcon />,
        url: routes.admin.manufacturers.list,
      },
      {
        title: 'SIDEBAR.VEHICLES',
        icon: <DirectionsCarIcon />,
        url: routes.admin.vehicles.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_CMS',
    items: [
      {
        title: 'SIDEBAR.BLOGS',
        icon: <ArticleIcon />,
        url: routes.admin.blogs.list,
      },
      {
        title: 'SIDEBAR.ARTICLE_CATEGORIES',
        icon: <ClassIcon />,
        url: routes.admin.articleCategories.list,
      },
      {
        title: 'SIDEBAR.STATIC_PAGES',
        icon: <NewspaperIcon />,
        url: routes.admin.staticPages.list,
      },
      {
        title: 'SIDEBAR.FAQ',
        icon: <QuestionIcon />,
        url: routes.admin.faq.list,
      },
      {
        title: 'SIDEBAR.PRICE_VARIANTS',
        icon: <PriceCheckIcon />,
        url: routes.admin.priceVariants.list,
      },
      {
        title: 'SIDEBAR.CLIENTS',
        icon: <PeopleIcon />,
        url: routes.admin.clients.list,
      },
    ],
  },
  {
    title: 'SIDEBAR.GROUP_SETTINGS',
    items: [
      {
        title: 'SIDEBAR.VAT_RATES',
        icon: <PercentIcon />,
        url: routes.admin.vatRates.list,
      },
      {
        title: 'SIDEBAR.USERS',
        icon: <AccountBox />,
        url: routes.admin.users.list,
        apiUrl: apiRoutes.users.list,
      },
      {
        title: 'SIDEBAR.TRANSLATIONS',
        icon: <SpellcheckIcon />,
        url: routes.admin.translations.list,
        apiUrl: apiRoutes.translations.list,
      },
    ],
  },
];
