import { GlobalStyle } from '../types/Config';

export const colors: GlobalStyle = {
  textColor: 'rgb(33, 43, 54)',
  textSubtitleColor: 'rgb(99, 115, 129)',
  backgroundColor: 'rgb(255,255,255)',
  inputFocusColor: 'rgb(33, 43, 54)',
  inputBorderColor: 'rgba(145, 158, 171, 0.2)',
  inputLabelColor: 'rgb(145, 158, 171)',
  inputErrorColor: 'rgb(255, 86, 48)',
  buttonContainedPrimaryColor: 'rgb(33, 43, 54)',
  buttonContainedPrimaryColorHover: 'rgb(69, 79, 91)',
  buttonOutlinedPrimaryColor: 'transparent',
  buttonOutlinedPrimaryColorBorder: 'rgba(145,158,171,0.32)',
  buttonOutlinedPrimaryColorHover: 'rgba(145, 158, 171, 0.08)',
  buttonErrorBackgroundColor: 'rgba(255, 86, 48, 0.16)',
  buttonErrorBackgroundColorHover: 'rgba(255, 86, 48, 0.32)',
  buttonErrorColor: 'rgb(183,29,24)',
  buttonSoftBackgroundColor: 'rgba(145, 158, 171, 0.08)',
  buttonSoftBackgroundHoverColor: 'rgba(145, 158, 171, 0.24)',
  buttonSuccessBackgroundColor: 'rgb(0, 167, 111)',
  buttonSuccessBackgroundColorHover: 'rgb(0, 120, 103)',
  buttonSuccessColor: 'rgb(255, 255, 255)',
  dividerColor: 'rgb(145, 158, 171)',
  dividerBorderColor: 'rgba(145, 158, 171, 0.2)',
  dividerBorderColorLighter: 'rgba(145, 158, 171, 0.1)',
  dividerBorderColorLightest: 'rgba(0, 0, 0, 0.04)',
  progressBackgroundColor: 'rgba(33, 43, 54, 0.3)',
  progressColor: 'rgb(33, 43, 54)',
  tabDefaultColor: 'rgb(99, 115, 129)',
  tableHeadBackgroundColor: 'rgb(244, 246, 248)',
  tableHeadColor: 'rgb(99, 115, 129)',
  tableHeadBulkColor: 'rgba(0, 167, 111)',
  tableHeadBulkBackgroundColor: 'rgb(200, 250, 214)',
  tableLineBorderColor: 'rgb(241, 243, 244)',
  tableLineHighlightColor: 'rgba(0, 167, 111, 0.08)',
  tableLineHoverColor: 'rgba(145, 158, 171, 0.08)',
  alertPrimaryBackgroundColor: 'rgb(33, 43, 54)',
  alertPrimaryColor: 'rgb(255,255,255)',
  alertErrorBackgroundColor: 'rgba(255, 86, 48, 0.16)',
  alertErrorColor: 'rgb(183,29,24)',
  alertErrorBackgroundColorHover: 'rgb(255, 86, 48)',
  alertErrorColorHover: 'rgb(255,255,255)',
  alertWarningBackgroundColor: 'rgba(255, 171, 0, 0.16)',
  alertWarningColor: 'rgb(183,110,0)',
  alertWarningBackgroundColorHover: 'rgb(255, 171, 0)',
  alertWarningColorHover: 'rgb(33, 43, 54)',
  alertSuccessBackgroundColor: 'rgba(34, 197, 94, 0.16)',
  alertSuccessColor: 'rgb(17,141,87)',
  alertSuccessBackgroundColorHover: 'rgba(34, 197, 94)',
  alertSuccessColorHover: 'rgb(255, 255, 255)',
  breadcrumbSecondaryColor: 'rgb(145, 158, 171)',
  switcherColor: 'rgb(0, 167, 111)',
  switcherThumbColor: 'rgb(255, 255, 255)',
  switcherTrackColor: 'rgba(145, 158, 171, 0.48)',
  avatarPlaceholderColor: 'rgb(255,255,255)',
  avatarPlaceholderBackgroundColor: 'rgba(20, 26, 33, 0.64)',
  countryBackgroundColor: 'rgb(244, 246, 248)',
  noDataBackgroundColor: 'rgba(145, 158, 171, 0.04)',
  noDataBorderColor: 'rgba(145, 158, 171, 0.08)',
  noDataColor: 'rgb(145, 158, 171)',
  popoverArrowBorderColor: 'rgba(145, 158, 171, 0.12)',
  popoverArrowBackgroundColor: 'rgba(255, 255, 255, 0.8)',
  sidebarColor: 'rgb(0, 167, 111)',
  sidebarBackgroundColor: 'rgba(0, 167, 111, 0.08)',
  sidebarGroupColor: 'rgb(145, 158, 171)',
  sidebarExpandColor: 'rgba(255, 255, 255, 0.48)',
  topNavigationBackgroundColor: 'rgba(255, 255, 255, 0.8)',
  checkboxColor: 'rgb(0, 167, 111)',
  tooltipColor: 'rgb(33, 43, 54)',
  pickerActiveBackgroundColor: 'rgb(0, 167, 111)',
  pickerActiveBackgroundHoverColor: 'rgb(17, 141, 87)',
  pickerHoverColor: 'rgba(0, 167, 111, 0.08)',
  loaderBackgroundColor: 'rgba(255, 255, 255, 0.8)',
  kanbanCardCountColor: 'rgba(145, 158, 171, 0.16)',
  kanbanCardCountBorderColor: 'rgba(145, 158, 171, 0.24)',
  disabledColor: 'rgb(145, 158, 171)',
  primaryLightColor: 'rgb(91, 228, 155)',
  tableOutlinedBorderColor: 'rgba(145, 158, 171, 0.16)',
};
