import { baseApiRoutes } from '../../core/config/apiRoutes';

export const apiRoutes = {
  ...baseApiRoutes,
  categories: {
    get: 'categories/:id',
    list: 'categories',
    all: 'categories/all',
    update: 'categories/:id',
    updateHierarchy: 'categories/hierarchy',
    updateTranslations: 'categories/:id/translations',
    updateSeoTranslations: 'categories/:id/seo-translations',
  },
  articleCategories: {
    list: 'article-categories',
    get: 'article-categories/:id',
    all: 'article-categories/all',
    create: 'article-categories',
    update: 'article-categories/:id',
    delete: 'article-categories/:id',
    updateTranslations: 'article-categories/:id/translations',
    updateSeoTranslations: 'article-categories/:id/seo-translations',
    categoriesOptions: 'article-categories/categories-options',
  },
  brands: {
    get: 'brands/:id',
    list: 'brands',
    update: 'brands/:id',
    updateSeoTranslations: 'brands/:id/seo-translations',
  },
  manufacturers: {
    get: 'manufacturers/:id',
    list: 'manufacturers',
    update: 'manufacturers/:id',
  },
  vehicles: {
    list: 'vehicles',
    getVehiclesStatistics: 'vehicles/statistics',
  },
  products: {
    get: 'products/:id',
    getLatest: 'products/latest',
    getSoldProductsStatistics: 'products/sold-products-statistics',
    getImportedProductsStatistics: 'products/imported-products-statistics',
    list: 'products',
    update: 'products/:id',
    updateSeoTranslations: 'products/:id/seo-translations',
  },
  blogs: {
    list: 'blogs',
    get: 'blogs/:id',
    create: 'blogs',
    update: 'blogs/:id',
    updateTranslations: 'blogs/:id/translations',
    updateSeoTranslations: 'blogs/:id/seo-translations',
    delete: 'blogs/:id',
  },
  priceVariants: {
    list: 'price-variants',
    get: 'price-variants/:id',
    create: 'price-variants',
    update: 'price-variants/:id',
    updateTranslations: 'price-variants/:id/translations',
    delete: 'price-variants/:id',
    priceVariantsOptions: 'price-variants/options',
  },
  clients: {
    list: 'clients',
    get: 'clients/:id',
    create: 'clients',
    update: 'clients/:id',
    updateTranslations: 'clients/:id/translations',
    delete: 'clients/:id',
    usersList: 'clients/:id/users',
  },
  clientRepresentatives: {
    get: 'client-representatives/:id',
    list: 'client-representatives',
    create: 'client-representatives',
    update: 'client-representatives/:id',
    delete: 'client-representatives/:id',
    bulkDelete: 'client-representatives/bulk-delete',
  },
  staticPages: {
    list: 'static-pages',
    get: 'static-pages/:id',
    update: 'static-pages/:id',
    updateTranslations: 'static-pages/:id/translations',
    updateSeoTranslations: 'static-pages/:id/seo-translations',
  },
  orders: {
    list: 'orders',
    options: 'orders/options',
    getOrdersStatistics: 'orders/statistics',
    clientOrdersList: 'orders/client',
  },
  orderItems: {
    options: 'order-items/options',
    optionsByOrder: 'order-items/options/:id',
  },
  vatRates: {
    get: 'vat-rates/:id',
    list: 'vat-rates',
    update: 'vat-rates/:id',
  },
  baskets: {
    list: 'baskets',
  },
  faq: {
    list: 'faq',
    get: 'faq/:id',
    create: 'faq',
    update: 'faq/:id',
    updateTranslations: 'faq/:id/translations',
    delete: 'faq/:id',
  },
  returnOrders: {
    list: 'return-orders',
    get: 'return-orders/:id',
    create: 'return-orders',
    update: 'return-orders/:id',
    delete: 'return-orders/:id',
  },
  promoCodes: {
    list: 'promo-codes',
    get: 'promo-codes/:id',
    create: 'promo-codes',
    update: 'promo-codes/:id',
    delete: 'promo-codes/:id',
  },
};
